
  @media screen and (max-width:1500px) {
    .nav-item{
        font-size: 15px;
      }
    
    
}


@media screen and (max-width:1024px) {
    .nav-item{
        font-size: 15px;
      }
    
    

}

  @media screen and (max-width:991px) {
    .img-container{
        margin-top: 17px;
    }
    .rotate {
        /* animation: rotation 4s infinite linear; */
        width: 300px !important; 
        margin-left: -20px !important;
      }
}

@media screen and (max-width:767px) {
    .nav-item{
        font-size: 15px;
      }
    
    
}

@media screen and (max-width:479px) {
    .nav-item{
        font-size: 15px;
      }
    
    
}