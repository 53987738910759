.rotate {
    animation: rotation 4s infinite linear;
    width: 360px !important; 
    /* margin-left: 100px;
    margin-bottom: 20px; */
  }
  .img-container{
    margin-top: 50px;
}
  @keyframes rotation {
    from {
      transform: rotateX(0deg);
    }
    to {
      transform: rotateY(359deg);
    }
  }


  @media screen and (max-width:1500px) {
    .img-container{
        margin-top: 35px;
    }

}


@media screen and (max-width:1024px) {
    .rotate {
      /* animation: rotation 4s infinite linear; */
      width: 330px !important; 
      margin-left: -20px !important;
    }

}

  @media screen and (max-width:991px) {
    .img-container{
        margin-top: 17px;
    }
    .rotate {
        /* animation: rotation 4s infinite linear; */
        width: 300px !important; 
        margin-left: -20px !important;
      }
}

@media screen and (max-width:767px) {
    .img-container{
        margin-top: 15px;
    }
    .rotate {
        /* animation: rotation 4s infinite linear; */
        width: 280px !important; 
        margin-left: -20px !important;
      }
}

@media screen and (max-width:479px) {
    .img-container{
        margin-top: 55px !important;
    }
    .rotate {
        /* animation: rotation 4s infinite linear; */
        width: 200px !important; 
        margin-left: -20px !important;
      }
}