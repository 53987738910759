.metamask-btn{
    text-align: center;
    justify-content: center;
    /* display: flex */
    border-radius: 30px;
    width: 300px;
    height: 60px;
    margin-bottom: 20px;
    background: #a44d7f;
    color: whitesmoke;
    font-weight: 700;
    font-size: 19px;
    margin-top: 10px
}
.metamask-btn-img{
    width: 50px;
    margin-right: 7px
}

.trustwallet-btn{
    text-align: center;
    justify-content: center;
    /* display: flex */
    border-radius: 30px;
    width: 300px;
    height: 60px;
    background: #a44d7f;
    color: whitesmoke;
    font-weight: 700;
    font-size: 19px

}


.trustwallet-btn-img{
    width: 50px;
    margin-right: 7px

}
