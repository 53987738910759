.paper-container{
    display: flex;
    justify-content: center;
}

.paper-inner-container{
    /* max-width: 100%; */
    width: 90%;
    /* min-width: 300px; */
    justify-content: space-between;
    display: flex;
    align-items: center;
    /* height: 150px; */
    font-size: 20px;
    background-color: #a44c7e
;
    /* background-image: linear-gradient(to left bottom, #2f3032, #303133, #313134, #323234, #333335, #333335, #333335, #333335, #323234, #313133, #303033, #2f2f32); */
                    border-style: none;
    border-radius: 15px;
    padding: 25px 30px 10px 30px
}
@media screen and (max-width: 500px) {
    .paper-inner-container{
        flex-direction: column;
    }   
}
.paper-remainig-container{
}

.paper-remaining{
    color: white !important;
    font-size: 20px;
}

.paper-heading-remaining{
    color: white !important;
    font-size: 25px;
}