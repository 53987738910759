.lm-main-container{
    display: flex;
    margin-top: 45px;
    justify-content: space-between;
    width: 100%
}
.lm-first-img-container{

}

.lm-first-img{
    width: 260px;
}