
 .video1{
  margin-top: 20px;
  max-width:64rem;
  /* width:100%; */
  max-height:30.875rem;
  height:100%;
  margin:1.25rem auto;
  padding:1.051%;
  /* background-color:#666; */
  text-align: center
}
.videos1{
  width: 405px;
  height: 350px;
}

.title-box {
  position: relative;
  text-align: center;
}



.platform .button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
}

@media screen and (max-width: 1400px) {

  .video1{
      margin-top: 20px;
      max-width:64rem;
      width:100%;
      max-height:30.875rem;
      height:90%;
      margin:1.25rem auto;
      padding:1.051%;
      /* background-color:#666; */
  }
  .videos1{
      width: 200px;
  }
  .button-box {
      width: 500px;
      /* margin-right: 4% */
    }
    .videos1{
      width: 400px;
      height: 350px;
    }
}
.vs-heading{
  color: white
}



@media screen and (max-width: 700px) {
  .platform .button-box {
    flex-direction: column;
  }
  .platform .button-box .btn-line {
    height: 10px;
    background: transparent;
  }
}


@media screen and (max-width: 480px) {
  .video1{
      margin-top: 20px;
      max-width:64rem;
      width:100%;
      max-height:30.875rem;
      height:100%;
      /* margin:1.25rem auto; */
      padding:1.051%;
      /* background-color:#666; */
  }


    .videos1{
      width: 395px;
      height: 350px;
    }
}



@media screen and (max-width: 410px) {
  .platform .button-box {
    display: block;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 100px;
    margin-bottom: 1000px;
    margin-top: -1150px
  }

  .platform .button-box {
      display: block;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;
      margin-top: 100px
    }
    .videos1{
      width: 350px;
      height: 330px;
    }
}
