.hero-section-main {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: 12%;
  margin-right: 12%;
}

.hero-btn-container {
  display: flex;
  justify-content: center;
}

.hero-para {
  /* width: 700px */
}

.hero-coontent-container {
  /* display: flex; */
  /* justify-content: center; */
}
.opening-time-heading{
  margin-top: 20px;
  margin-right: 15px;
  color: white
}
.paper-container-hero{
  margin-top: 150px
}
.rotate {
  animation: rotation 4s infinite linear;
  width: 320px !important;
}

.img-container {
  margin-top: 50px;
    display: flex;
  justify-content: center;
  margin-left: -100px
}

@keyframes rotation {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

@media screen and (max-width:1366px) {
  .img-container {
    margin-top: 50px;
  }
  .rotate {
    /* animation: rotation 4s infinite linear; */
    width: 360px !important;
    margin-left: -20px !important;
  }
  .hero-section-main {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media screen and (max-width:1024px) {
  .rotate {
    /* animation: rotation 4s infinite linear; */
    width: 330px !important;
    margin-left: -20px !important;
  }
  .hero-section-main {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media screen and (max-width:991px) {
  .hero-img-container {
    margin-top: 10px;
  }
  .hero-section-main {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media screen and (max-width:767px) {
  .hero-img-container {
    margin-top: 20px;
  }
  .hero-section-main {
    margin-left: 4%;
    margin-right: 4%;
  }
  .img-container {
    margin-top: 15px;
  }
  .rotate {
    /* animation: rotation 4s infinite linear; */
    width: 280px !important;
    margin-left: -20px !important;
  }
}

@media screen and (max-width:479px) {

  .img-container {
    margin-top: 33px;
      display: flex;
    justify-content: center;
    margin-left: -60px
  }
  
  .hero-img-container {
    margin-top: 33px;
  }
  .rotate {
    /* animation: rotation 4s infinite linear; */
    width: 200px !important;
    margin-left: -20px !important;
  }
  .hero-section-main {
    margin-left: 1%;
    margin-right: 1%;
  }
  .hero-btn-container {
    margin-left: 30px;
    margin-right: 30px;
    display: block;
    justify-content: center;
  }
}